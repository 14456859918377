<template>
  <div style="overflow-x: hidden">
    <div class="header">
      <Header
        @search="searchValue"
        :show-explain="true"
        explain-text="拼团说明"
        @explain="showDialg"
      ></Header>
      <!--      <div class="desc-box" @click="showDialg()">拼团说明</div>-->
    </div>
    <div class="joinGroup">
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        :loading="loading"
        @load="nextPage"
        class="lists"
      >
        <van-row
          class="filmItem float-item"
          v-for="(film, index) in groupList"
          :key="index"
        >
          <group-card
            :key="index"
            :id="film.id"
            @addGroup="addGroup"
            @shareGroup="shareGroup(film)"
            :film-id="film.id"
            :film-name="film.name"
            :film-img="film.img"
            :film-number="film.number"
            :film-time="film.time"
            :film-date="film.date"
            :film-address="film.address"
            :film-user="film.user"
            :film-year="film.year"
            :is-disabled="film.isJoin"
            @handleMovie="handleMovie(film.filmId)"
          >
          </group-card>
        </van-row>
      </van-list>
    </div>
    <van-empty
      v-show="groupList.length < 1"
      description="未查到拼团记录"
    ></van-empty>
  </div>
</template>

<script>
import Header from '@comp/header/index.vue'
import GroupCard from '@comp/movieCard/groupCard.vue'
import { Dialog, Toast } from 'vant'
import { api } from '@api'

export default {
  name: 'joinGroupView',
  components: {
    Header,
    GroupCard,
  },
  data() {
    return {
      finished: false,
      loading: false,
      groupList: [],
      filter: {
        expectPlayPlace: '',
        filmName: '',
        type: '',
        pageNo: 0,
        pageSize: 10,
      },
    }
  },
  mounted() {
    // this.init();
  },
  methods: {
    //说明弹框
    showDialg() {
      Dialog.alert({
        title: '拼团说明',
        message:
          '1、用户在“我要点播”模块中，成功提交的点播需求将自动发起一场拼团，其他用户可一起点播，每人每场只可以一起点播一次。拼团人数越多的场次，当地农村电影放映公司将优先安排进行放映。\n' +
          '\n' +
          '2、具体放映安排以放映公告为准，如有疑问，请咨询当地农村电影放映公司。',
      })
    },
    // 加载拼团列表
    getGroupList() {
      api.getGroupList(this.filter).then((res) => {
        const { records } = res
        this.loading = false
        // console.log(records.length,this.filter.pageSize);
        if (records.length === 0) {
          this.finished = true
        } else {
          records.map((item) => {
            this.groupList.push({
              id: item.id,
              name: item.filmName,
              img: item.filmPicture,
              filmId: item.filmId,
              number: item.spellGroup,
              time: item.createTime,
              date: `${item.expectPlayStartTime}至${item.expectPlayEndTime}`,
              address: item.expectPlayPlace,
              user: item.submitRealName,
              year: item.filmYear,
              isJoin: item.isJoin,
            })
          })
        }
      })
    },
    // 搜索
    searchValue(value) {
      this.filter.filmName = value
      this.filter.expectPlayPlace = this.$store.state.userAddress.join('')
      this.groupList = []
      this.filter.pageNo = 0
      console.log(this.filter)
      this.finished = false
      this.nextPage()
    },
    nextPage() {
      // 加载更多
      if (this.$store.state.userAddress) {
        this.filter.expectPlayPlace = this.$store.state.userAddress.join('')
      }
      // console.log(this.filter);
      // console.log("执行加载更多");
      this.loading = true
      this.filter.pageNo++
      this.getGroupList()
    },
    addGroup(groupId) {
      // console.log(groupId);
      Dialog.confirm({
        title: '提示',
        message: '是否加入拼团？',
      })
        .then(() => {
          api
            .joinGroup({
              personalClickId: groupId,
            })
            .then((res) => {
              console.log(res)
              if (res.success) {
                Dialog.alert({
                  title: '提示',
                  message: '加入拼团成功！',
                }).then(() => {
                  // console.log(this.groupList);
                  this.groupList.map((item) => {
                    if (item.id === groupId) {
                      item.isJoin = 1
                      item.number++
                    }
                  })
                  // this.getGroupList();
                })
              } else {
                Dialog.alert({
                  title: '提示',
                  message: res.message,
                })
              }
            })
        })
        .catch(() => {
          Toast('取消加入拼团')
        })
      // 确认参加该拼团吗

      // Toast(`加入成功`+groupId);
    },
    shareGroup(film) {
      // console.log(groupId);
      // Toast(`分享成功`+film.name);
      ZWJSBridge.share({
        title: `浙影通-邀请您参加拼团《${film.name}》`,
        content: `邀请您去：🗺${film.address} ⏰${film.date}, 一起拼团看电影🎬《${film.name}》`,
        // 'image':"http://www.dtdream.com/r/cms/www/default/img/default/sm-logo-82-54.png",
        url: 'https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2002207363/reserved/index.html#/home',
      })
        .then((result) => {
          console.log(result)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleMovie(film) {
      console.log(film)
      //跳转到影片详情页面
      this.$router.push({
        path: `/filmDetail`,
        query: {
          id: film,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.desc-box {
  font-size: 14px;
  background: #2c72f7;
  color: #ffffff;
  width: 80px;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
}

.header {
  padding: 0 10px;
}

.joinGroup {
  width: 100%;
}
.lists {
  width: 100%;
  max-height: calc(100vh - 60px);
}
.box-button {
  width: 100%;
  height: 61px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.27);
}
</style>
